import { useEffect, useState } from "react";
import { createBrowserRouter, defer, redirect, RouterProvider } from "react-router-dom";
import { stripBasename } from "@remix-run/router";
import { RestAPI } from "@aws-amplify/api-rest";

import Layout from "./layout/Layout.jsx";
import "./styles/layout/layout.scss";

import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import Login from "./pages/Login/Login.js";
import AppWithAuthenticator from "./AuthWrapper/AppWithAuthenticator.jsx";

import Main from "./pages/Main/Main.js";
import NoAccess from "./pages/NoAccess/NoAccess.js";
import { getSiteDesc } from "./utils/getSite.js";

const basename = undefined;
const getRouter = () => createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: ":site?",
                element: <Main />,
                loader: ({ request, params }) => {
                    const originalUrl = globalThis.sessionStorage.getItem("loginUrl");

                    // if we do have a saved url (before the external login started, redirect to it)
                    if (originalUrl) {
                        globalThis.sessionStorage.removeItem("loginUrl"); // clean

                        const urlObject = new URL(originalUrl);
                        const path = stripBasename(urlObject.pathname, basename || "");
                        if (path) return redirect(path + urlObject.search + urlObject.hash);
                    }

                    const { site } = params;
                    const searchParams = (new URL(request.url)).searchParams;
                    const site_proprofs = getSiteDesc(searchParams.get("site") ?? "", site ?? "kb");

                    const apiPromise = RestAPI.get("apiApplication", "/jwt", {
                        queryStringParameters: { site: site_proprofs }
                    });
                    return defer({ promise: apiPromise });
                },
                errorElement: <NoAccess />,
                children: []
            }
        ]
    }
], { basename });

function App() {
    // loader functions will be called inmediatly when createBrowserRouter is called, if the route is correct
    // to prevent failing because we have no auth-data, defer the router creation to the mounting of the app component
    const [routerInfo, setRouterInfo] = useState({ router: null, idx: 0 });

    useEffect(() => {
        const router = getRouter();
        setRouterInfo((ri) => ({ router, idx: (ri.idx + 1) }));

        // we need to clear the memory of the previous router
        return () => router.dispose();
    }, []);

    if (!routerInfo.router) return null;
    return (
        <RouterProvider
            key={routerInfo.idx} router={routerInfo.router} fallbackElement={
                <div>
                    ...
                </div>
            }
        />
    );
}

export default function WrappedApp() {
    /* return <App />; */
    const queryString = globalThis.location.search;
    const urlParams = new URLSearchParams(queryString);

    const return_page = urlParams.get("return_page");
    if (return_page) {
        // clean some params from previous run
        globalThis.sessionStorage.removeItem("return_page");
        globalThis.sessionStorage.removeItem("loginUrl");
    }

    return (
        <AppWithAuthenticator>
            {({ auth, ...props }) => (
                auth ? <App /> : <Login {...props} />
            )}
        </AppWithAuthenticator>
    );
}
