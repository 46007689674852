export const getSiteDesc = (site, default_proprofs_site) => {
    let site_proprofs = default_proprofs_site;

    if (site.includes("://")) {
        if (site.includes("://ims.")) {
            if (site.includes(".com/es")) {
                site_proprofs = "sgi_es";
            } else {
                site_proprofs = "sgi";
            }
        } else if (site.includes("://kb.")) {
            if (site.includes(".com/es")) {
                site_proprofs = "kb_es";
            } else {
                site_proprofs = "kb";
            }
        }
    }

    return site_proprofs;
};
