import { Await, useLoaderData, useParams, useSearchParams } from "react-router-dom";
import { Suspense, useEffect } from "react";

import { ProgressSpinner } from "primereact/progressspinner";

import { getSiteDesc } from "../../utils/getSite";

const Redirect = ({ to }) => {
    useEffect(() => {
        globalThis.sessionStorage.removeItem("return_page");
        globalThis.sessionStorage.removeItem("loginUrl");
        globalThis.location.href = to;
    }, []);

    return null;
};

const Main = () => {
    const { promise } = useLoaderData();
    const { site } = useParams();

    const [searchParams] = useSearchParams();
    const return_page = searchParams.get("return_page");
    const site_proprofs = getSiteDesc(searchParams.get("site") ?? "", site ?? "kb");

    let domain = site === "sgi" ? "https://ims.helpdocsonline.com/" : "https://kb.dropcontrol.com/";
    switch(site_proprofs) {
        case "sgi_es":
        case "sgi": {
            domain = "https://ims.helpdocsonline.com/";
            break;
        }
        case "kb_es":
        case "kb": {
            domain = "https://kb.dropcontrol.com/";
            break;
        }
        default: {
            //
        }
    }

    const kb_lang = site_proprofs.split("_")?.[1];
    return (
        <>
            <div>
                <ProgressSpinner />
                <Suspense fallback={null}>
                    <Await
                        resolve={promise}
                        children={(resolvedToken) => {
                            let return_url = domain + "access/jwtsso/?token=" + resolvedToken.message.token;
                            if (return_page) {
                                return_url = return_url + "&page=" + return_page;
                            }
                            if (kb_lang) {
                                return_url = return_url + "&kb_lang=" + kb_lang;
                            }

                            return <Redirect to={return_url} />;
                        }}
                    />
                </Suspense>
            </div>
        </>
    );
};

export default Main;
